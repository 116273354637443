import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { HomeIndexQuery } from '../../graphql-types';
import PostList from '../components/post-list';

const BlogIndex = ({ data }: PageProps<HomeIndexQuery>) => {
  return (
    <Layout>
      <Seo title="Home" />
      <PostList />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query HomeIndex {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
